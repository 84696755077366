////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(249, 236, 214);
}
.bg-thirdColor {
  background-color: rgb(249, 236, 214);
}
.bg-fourthColor {
  background-color: rgb(0, 98, 120);
}
.bg-fifthColor {
  background-color: rgb(0, 120, 147);
}
.bg-sixthColor {
  background-color: rgb(249, 236, 214);
}
.bg-seventhColor {
  background-color: rgb(255, 255, 255);
}
